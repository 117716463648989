%icon-ad-close {width:30px; height:30px; background-position:-239px -128px;}
%icon-arrow-top {width:50px; height:50px; background-position:-68px -128px;}
%icon-arrow_next {width:14px; height:22px; background-position:-40px -230px;}
%icon-arrow_prev {width:14px; height:22px; background-position:-58px -230px;}
%icon-back_arrow {width:18px; height:18px; background-position:-118px -196px;}
%icon-cart {width:20px; height:19px; background-position:-346px -33px;}
%icon-circle-arrow {width:30px; height:30px; background-position:-273px -128px;}
%icon-clock {width:20px; height:20px; background-position:-226px -33px;}
%icon-close-btn {width:20px; height:20px; background-position:-250px -33px;}
%icon-collection-black-m {width:20px; height:20px; background-position:-274px -33px;}
%icon-collection-black {width:22px; height:22px; background-position:-174px -33px;}
%icon-delete {width:16px; height:16px; background-position:-140px -196px;}
%icon-favorite {width:22px; height:22px; background-position:-200px -33px;}
%icon-favorite_heart_gray {width:24px; height:24px; background-position:-340px -60px;}
%icon-favorite_heart_red {width:24px; height:24px; background-position:-340px -88px;}
%icon-flash-error {width:64px; height:64px; background-position:0px -60px;}
%icon-flash-info {width:64px; height:64px; background-position:-68px -60px;}
%icon-flash-question {width:64px; height:64px; background-position:-136px -60px;}
%icon-flash-stop {width:64px; height:64px; background-position:-204px -60px;}
%icon-flash-success {width:64px; height:64px; background-position:-272px -60px;}
%icon-flash-warning {width:64px; height:64px; background-position:0px -128px;}
%icon-heart-empty {width:21px; height:18px; background-position:-68px -196px;}
%icon-heart-full {width:21px; height:18px; background-position:-93px -196px;}
%icon-ic-bar {width:30px; height:30px; background-position:-307px -128px;}
%icon-ic-close {width:30px; height:30px; background-position:0px -196px;}
%icon-icon_fb {width:35px; height:35px; background-position:-122px -128px;}
%icon-icon_ig {width:35px; height:35px; background-position:-161px -128px;}
%icon-icon_like {width:16px; height:16px; background-position:-160px -196px;}
%icon-icon_like_red {width:16px; height:16px; background-position:-180px -196px;}
%icon-icon_line {width:35px; height:35px; background-position:-200px -128px;}
%icon-left-arrow {width:16px; height:32px; background-position:0px -230px;}
%icon-logo-m {width:193px; height:29px; background-position:-174px 0px;}
%icon-logo {width:170px; height:56px; background-position:0px 0px;}
%icon-menu-delete {width:24px; height:24px; background-position:-341px -128px;}
%icon-question {width:16px; height:16px; background-position:-200px -196px;}
%icon-recommend_arrow_next {width:14px; height:22px; background-position:-76px -230px;}
%icon-recommend_arrow_prev {width:14px; height:22px; background-position:-94px -230px;}
%icon-right-arrow {width:16px; height:32px; background-position:-20px -230px;}
%icon-search-pc {width:16px; height:16px; background-position:-220px -196px;}
%icon-search {width:20px; height:20px; background-position:-298px -33px;}
%icon-user {width:20px; height:20px; background-position:-322px -33px;}
%icon-view_black {width:12px; height:14px; background-position:-240px -196px;}
%icon-view_gray {width:12px; height:14px; background-position:-256px -196px;}
%icon-warning {width:30px; height:30px; background-position:-34px -196px;}

$sprites-list: ()!default;
$sprites-list: map-merge($sprites-list, ("icon":(ad-close,arrow-top,arrow_next,arrow_prev,back_arrow,cart,circle-arrow,clock,close-btn,collection-black-m,collection-black,delete,favorite,favorite_heart_gray,favorite_heart_red,flash-error,flash-info,flash-question,flash-stop,flash-success,flash-warning,heart-empty,heart-full,ic-bar,ic-close,icon_fb,icon_ig,icon_like,icon_like_red,icon_line,left-arrow,logo-m,logo,menu-delete,question,recommend_arrow_next,recommend_arrow_prev,right-arrow,search-pc,search,user,view_black,view_gray,warning)));

.icon {background-image:url(../img/icon.png); background-size: 367px 262px; background-repeat:no-repeat; display:block;}

/********************************************
- List all sprites of full CSS class, ex:
  @include spritesCSS("icon");

- Insert a single sprite CSS property, ex:
  @extend .icon;
  @extend %icon-file-name;
********************************************/

@mixin spritesCSS($iconPrefix) {
@each $iconName in map-get($sprites-list,$iconPrefix) {
  .#{$iconPrefix}-#{$iconName} {
    @extend .#{$iconPrefix};
    @extend %#{$iconPrefix}-#{$iconName};
  }
}
}

@media only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
.icon { background-image: url(../img/icon@2x.png); background-size: 367px 262px; }
}
