.icon-ad-close {
  width: 30px;
  height: 30px;
  background-position: -239px -128px;
}

.icon-arrow-top {
  width: 50px;
  height: 50px;
  background-position: -68px -128px;
}

.icon-arrow_next {
  width: 14px;
  height: 22px;
  background-position: -40px -230px;
}

.icon-arrow_prev {
  width: 14px;
  height: 22px;
  background-position: -58px -230px;
}

.icon-back_arrow {
  width: 18px;
  height: 18px;
  background-position: -118px -196px;
}

.icon-cart {
  width: 20px;
  height: 19px;
  background-position: -346px -33px;
}

.icon-circle-arrow {
  width: 30px;
  height: 30px;
  background-position: -273px -128px;
}

.news-content .time:before, .news-frame .news-box .news-conetent span:before, .icon-clock {
  width: 20px;
  height: 20px;
  background-position: -226px -33px;
}

.icon-close-btn {
  width: 20px;
  height: 20px;
  background-position: -250px -33px;
}

.icon-collection-black-m {
  width: 20px;
  height: 20px;
  background-position: -274px -33px;
}

.icon-collection-black {
  width: 22px;
  height: 22px;
  background-position: -174px -33px;
}

.icon-delete {
  width: 16px;
  height: 16px;
  background-position: -140px -196px;
}

.icon-favorite {
  width: 22px;
  height: 22px;
  background-position: -200px -33px;
}

.icon-favorite_heart_gray {
  width: 24px;
  height: 24px;
  background-position: -340px -60px;
}

.icon-favorite_heart_red {
  width: 24px;
  height: 24px;
  background-position: -340px -88px;
}

.icon-flash-error {
  width: 64px;
  height: 64px;
  background-position: 0px -60px;
}

.icon-flash-info {
  width: 64px;
  height: 64px;
  background-position: -68px -60px;
}

.icon-flash-question {
  width: 64px;
  height: 64px;
  background-position: -136px -60px;
}

.icon-flash-stop {
  width: 64px;
  height: 64px;
  background-position: -204px -60px;
}

.icon-flash-success {
  width: 64px;
  height: 64px;
  background-position: -272px -60px;
}

.icon-flash-warning {
  width: 64px;
  height: 64px;
  background-position: 0px -128px;
}

.icon-heart-empty {
  width: 21px;
  height: 18px;
  background-position: -68px -196px;
}

.icon-heart-full {
  width: 21px;
  height: 18px;
  background-position: -93px -196px;
}

.icon-ic-bar {
  width: 30px;
  height: 30px;
  background-position: -307px -128px;
}

.icon-ic-close {
  width: 30px;
  height: 30px;
  background-position: 0px -196px;
}

.icon-icon_fb {
  width: 35px;
  height: 35px;
  background-position: -122px -128px;
}

.icon-icon_ig {
  width: 35px;
  height: 35px;
  background-position: -161px -128px;
}

.icon-icon_like {
  width: 16px;
  height: 16px;
  background-position: -160px -196px;
}

.icon-icon_like_red {
  width: 16px;
  height: 16px;
  background-position: -180px -196px;
}

.icon-icon_line {
  width: 35px;
  height: 35px;
  background-position: -200px -128px;
}

.icon-left-arrow {
  width: 16px;
  height: 32px;
  background-position: 0px -230px;
}

.icon-logo-m {
  width: 193px;
  height: 29px;
  background-position: -174px 0px;
}

.icon-logo {
  width: 170px;
  height: 56px;
  background-position: 0px 0px;
}

#prod-list tbody tr td del, .icon-menu-delete {
  width: 24px;
  height: 24px;
  background-position: -341px -128px;
}

.icon-question {
  width: 16px;
  height: 16px;
  background-position: -200px -196px;
}

.icon-recommend_arrow_next {
  width: 14px;
  height: 22px;
  background-position: -76px -230px;
}

.icon-recommend_arrow_prev {
  width: 14px;
  height: 22px;
  background-position: -94px -230px;
}

.icon-right-arrow {
  width: 16px;
  height: 32px;
  background-position: -20px -230px;
}

.icon-search-pc {
  width: 16px;
  height: 16px;
  background-position: -220px -196px;
}

.icon-search {
  width: 20px;
  height: 20px;
  background-position: -298px -33px;
}

.icon-user {
  width: 20px;
  height: 20px;
  background-position: -322px -33px;
}

.icon-view_black {
  width: 12px;
  height: 14px;
  background-position: -240px -196px;
}

.icon-view_gray {
  width: 12px;
  height: 14px;
  background-position: -256px -196px;
}

.icon-warning {
  width: 30px;
  height: 30px;
  background-position: -34px -196px;
}

.icon, .news-content .time:before, .news-frame .news-box .news-conetent span:before, #prod-list tbody tr td del, .icon-warning, .icon-view_gray, .icon-view_black, .icon-user, .icon-search, .icon-search-pc, .icon-right-arrow, .icon-recommend_arrow_prev, .icon-recommend_arrow_next, .icon-question, .icon-menu-delete, .icon-logo, .icon-logo-m, .icon-left-arrow, .icon-icon_line, .icon-icon_like_red, .icon-icon_like, .icon-icon_ig, .icon-icon_fb, .icon-ic-close, .icon-ic-bar, .icon-heart-full, .icon-heart-empty, .icon-flash-warning, .icon-flash-success, .icon-flash-stop, .icon-flash-question, .icon-flash-info, .icon-flash-error, .icon-favorite_heart_red, .icon-favorite_heart_gray, .icon-favorite, .icon-delete, .icon-collection-black, .icon-collection-black-m, .icon-close-btn, .icon-clock, .icon-circle-arrow, .icon-cart, .icon-back_arrow, .icon-arrow_prev, .icon-arrow_next, .icon-arrow-top, .icon-ad-close {
  background-image: url(../img/icon.png);
  background-size: 367px 262px;
  background-repeat: no-repeat;
  display: block;
}

/********************************************
- List all sprites of full CSS class, ex:
  @include spritesCSS("icon");

- Insert a single sprite CSS property, ex:
  @extend .icon;
  @extend %icon-file-name;
********************************************/
@media only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .icon, .news-content .time:before, .news-frame .news-box .news-conetent span:before, #prod-list tbody tr td del, .icon-warning, .icon-view_gray, .icon-view_black, .icon-user, .icon-search, .icon-search-pc, .icon-right-arrow, .icon-recommend_arrow_prev, .icon-recommend_arrow_next, .icon-question, .icon-menu-delete, .icon-logo, .icon-logo-m, .icon-left-arrow, .icon-icon_line, .icon-icon_like_red, .icon-icon_like, .icon-icon_ig, .icon-icon_fb, .icon-ic-close, .icon-ic-bar, .icon-heart-full, .icon-heart-empty, .icon-flash-warning, .icon-flash-success, .icon-flash-stop, .icon-flash-question, .icon-flash-info, .icon-flash-error, .icon-favorite_heart_red, .icon-favorite_heart_gray, .icon-favorite, .icon-delete, .icon-collection-black, .icon-collection-black-m, .icon-close-btn, .icon-clock, .icon-circle-arrow, .icon-cart, .icon-back_arrow, .icon-arrow_prev, .icon-arrow_next, .icon-arrow-top, .icon-ad-close {
    background-image: url(../img/icon@2x.png);
    background-size: 367px 262px;
  }
}
#wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
  background: #FCFBFA;
}

#content-frame {
  max-width: 1530px;
  width: 100%;
  margin: 20px auto auto auto;
  padding: 30px 10px;
  min-height: 60vh;
  /*display: flex;
  align-items: center;*/
}

.login-frame .login {
  max-width: 800px;
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  color: #0c0c0c;
}
@media screen and (max-width: 700px) {
  .login-frame .login {
    display: block;
  }
}
.login-frame .login .box {
  max-width: 295px;
  width: 100%;
  display: block;
}
@media screen and (max-width: 700px) {
  .login-frame .login .box {
    margin: 30px auto;
  }
}
.login-frame .login .box .link-style {
  width: 100%;
  background: #0c0c0c;
  color: #fff;
  width: 100%;
  display: block;
  text-align: center;
  text-decoration: none;
  padding: 2px 0;
  border: 0;
}
.login-frame .login .box .or {
  font-size: 20px;
  margin: 20px 0;
}
.login-frame .login a {
  text-decoration: underline;
}
.login-frame .login h3 {
  font-size: 30px;
  border-bottom: 1px #0C0C0C solid;
  padding-bottom: 5px;
}
.login-frame .login form {
  margin: 30px 0;
}
.login-frame .login form [type=text], .login-frame .login form [type=email], .login-frame .login form [type=password], .login-frame .login form [type=email], .login-frame .login form select {
  margin-bottom: 15px;
}

.modify-member-frame {
  width: 100%;
}
.modify-member-frame form {
  max-width: 520px;
  width: 100%;
  margin: auto;
}
.modify-member-frame a {
  font-family: "Montserrat-Medium", "Noto_Medium";
}

.group {
  display: flex;
  margin-bottom: 15px;
}
@media screen and (max-width: 600px) {
  .group.flex {
    display: flex;
  }
  .group.flex > label {
    width: auto;
  }
  .group.flex > .input-styel {
    flex: 1 0;
  }
}
@media screen and (max-width: 600px) {
  .group {
    display: block;
  }
}
.group > label {
  width: 140px;
  text-align: right;
  margin-bottom: 0;
  line-height: 25px;
}
@media screen and (max-width: 600px) {
  .group > label {
    width: 100%;
    text-align: left;
  }
}
.group > .input-styel {
  width: calc(100% - 140px);
  padding-left: 5px;
  line-height: 25px;
}
@media screen and (max-width: 600px) {
  .group > .input-styel {
    width: 100%;
    padding-left: 0;
  }
}
.group > .input-styel .address {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.group > .input-styel .address * {
  width: 32%;
}
.group > .input-styel .address [type=text] {
  background: #f1f1f1;
}

[type=text], [type=email], [type=password], select {
  width: 100%;
  border: 1px #0C0C0C solid;
  margin: 10px 0;
  height: 25px;
  line-height: 25px;
  background: transparent;
  padding: 0 10px;
  margin: 0;
}

select {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-image: url(../img/select-arrow.png);
  background-size: 22px 8px;
  background-repeat: no-repeat;
  background-position: right;
}

[type=submit] {
  background: #0C0C0C;
  color: #fff;
  width: 50px;
  border: 0;
  padding: 2px 0;
  margin: auto;
  display: block;
}

hr {
  border-color: #0C0C0C;
}

.order-frame {
  max-width: 1100px;
  width: 100%;
  margin: auto;
}
.order-frame h2 {
  font-size: 16px;
  text-align: center;
  margin: 0 0 20px 0;
  font-family: "Montserrat-Medium", "Noto_Medium";
}
.order-frame .detail-frame .detail-box {
  max-width: 200px;
  width: 100%;
}
.order-frame .detail-frame .detail-box .detail-form {
  width: 100%;
}
@media screen and (max-width: 740px) {
  .order-frame .order-table.dividend table tbody tr td {
    width: 100%;
  }
}
.order-frame .order-table table {
  width: 100%;
}
@media screen and (max-width: 740px) {
  .order-frame .order-table table {
    display: block;
    width: 100%;
  }
}
.order-frame .order-table table thead {
  border-top: 1px #0C0C0C solid;
  border-bottom: 1px #0C0C0C solid;
}
@media screen and (max-width: 740px) {
  .order-frame .order-table table thead {
    display: none;
  }
}
.order-frame .order-table table thead tr th {
  padding: 5px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
}
@media screen and (max-width: 740px) {
  .order-frame .order-table table tbody {
    display: block;
    width: 100%;
  }
  .order-frame .order-table table tbody * {
    display: block;
    width: 100%;
  }
}
.order-frame .order-table table tbody tr {
  border-bottom: 1px #0C0C0C solid;
}
@media screen and (max-width: 740px) {
  .order-frame .order-table table tbody tr {
    padding: 5px 0;
  }
  .order-frame .order-table table tbody tr:nth-child(1) {
    border-top: 1px #0C0C0C solid;
  }
  .order-frame .order-table table tbody tr:after {
    content: " ";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }
}
.order-frame .order-table table tbody tr td {
  text-align: center;
  font-size: 12px;
  padding: 7px 5px;
  font-family: "Montserrat-Medium", "Noto_Medium";
}
.order-frame .order-table table tbody tr td font {
  display: none;
}
.order-frame .order-table table tbody tr td a {
  text-decoration: underline;
}
.order-frame .order-table table tbody tr td button {
  background: #ddd;
  border: 0;
  border-radius: 5px;
  font-family: Noto_Regular;
}
@media screen and (max-width: 740px) {
  .order-frame .order-table table tbody tr td {
    width: 50%;
    float: left;
    display: flex;
    text-align: left;
    font-size: 14px;
    font-family: "Montserrat-Regular", "Noto_Regular";
  }
  .order-frame .order-table table tbody tr td font {
    display: block;
    width: 70px;
  }
  .order-frame .order-table table tbody tr td span {
    width: calc(100% - 70px);
  }
  .order-frame .order-table table tbody tr td a {
    text-align: center;
    text-decoration: none;
    border: 1px #0C0C0C solid;
    padding: 5px;
  }
  .order-frame .order-table table tbody tr td button {
    width: 90px;
    margin-left: auto;
    padding: 5px;
  }
  .order-frame .order-table table tbody tr td.block {
    width: 100%;
    display: block;
  }
  .order-frame .order-table table tbody tr td.block font {
    width: auto;
  }
  .order-frame .order-table table tbody tr td.block span {
    width: auto;
    flex: 1 0;
  }
}
@media screen and (max-width: 740px) {
  .order-frame .details-order .details-table1 * {
    display: block;
    width: 100%;
  }
}
.order-frame .details-order .details-table1 thead {
  border-bottom: 1px #0C0C0C solid;
  border-top: 1px #0C0C0C solid;
}
.order-frame .details-order .details-table1 thead tr th {
  text-align: center;
  padding: 5px;
}
.order-frame .details-order .details-table1 > tbody > tr > td {
  padding: 20px 10px;
}
@media screen and (max-width: 740px) {
  .order-frame .details-order .details-table1 > tbody > tr > td table tbody tr:after {
    content: " ";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }
}
.order-frame .details-order .details-table1 > tbody > tr > td table tbody tr td {
  padding: 5px;
}
@media screen and (max-width: 740px) {
  .order-frame .details-order .details-table1 > tbody > tr > td table tbody tr td {
    width: 50%;
    float: left;
    text-align: left;
    padding: 10px 5px;
  }
  .order-frame .details-order .details-table1 > tbody > tr > td table tbody tr td.block {
    width: 100%;
    display: block;
  }
}

#prod-list {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  font-size: 14px;
}
#prod-list thead tr th {
  border-bottom: 1px solid #0C0C0C;
  padding: 5px;
  vertical-align: top;
  border-top: 1px solid #0C0C0C;
}
@media screen and (max-width: 740px) {
  #prod-list thead {
    display: none;
  }
}
#prod-list tbody tr {
  border-bottom: 1px solid #0C0C0C;
}
@media screen and (max-width: 740px) {
  #prod-list tbody tr {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #0C0C0C;
    display: flex;
    flex-wrap: wrap;
  }
  #prod-list tbody tr:nth-child(1) {
    border-top: 1px solid #0C0C0C;
  }
  #prod-list tbody tr:after {
    display: block;
    content: "";
    clear: both;
  }
}
#prod-list tbody tr td {
  padding: 10px 5px;
  vertical-align: top;
}
#prod-list tbody tr td select {
  height: 20px;
  line-height: 20px;
}
#prod-list tbody tr td .p-img {
  display: block;
  width: 80px;
  margin: 0 auto;
}
#prod-list tbody tr td .p-img img {
  width: 100%;
}
.cart-payment #prod-list tbody tr td .p-img {
  width: 40px;
  height: 65px;
}
@media screen and (max-width: 740px) {
  #prod-list tbody tr td .p-img {
    width: 140px;
  }
}
#prod-list tbody tr td span.offer {
  display: block;
  color: #C1272D;
}
#prod-list tbody tr td .info {
  background: #C1272D;
  font-size: 12px;
  display: inline-block;
  color: #fff;
  padding: 3px 5px;
  margin-top: 5px;
}
#prod-list tbody tr td .info.mob {
  display: none;
}
@media screen and (max-width: 740px) {
  #prod-list tbody tr td .info.pc {
    display: none;
  }
  #prod-list tbody tr td .info.mob {
    display: inline-block;
  }
}
#prod-list tbody tr td.gift {
  text-align: center;
}
@media screen and (max-width: 740px) {
  #prod-list tbody tr td.gift {
    width: 100%;
  }
}
#prod-list tbody tr td del {
  display: block;
  text-indent: -999px;
  overflow: hidden;
  margin: 0 auto;
  cursor: pointer;
  transform: scale(0.7);
}
#prod-list tbody tr td.mob-show {
  display: none;
}
@media screen and (max-width: 740px) {
  #prod-list tbody tr td.mob-show {
    display: block;
  }
}
@media screen and (max-width: 740px) {
  #prod-list tbody tr td {
    width: 50%;
    float: left;
    text-align: left;
  }
  #prod-list tbody tr td.total {
    text-align: right;
  }
  #prod-list tbody tr td.block {
    width: 100%;
  }
  #prod-list tbody tr td.pc-show {
    display: none;
  }
  #prod-list tbody tr td font {
    display: inline-block;
    width: auto;
  }
  #prod-list tbody tr td:before {
    content: attr(data-th);
    display: inline-block;
    color: #0C0C0C;
  }
}

.detail-frame {
  margin-left: auto;
}
.detail-frame.orders {
  margin: auto;
}
.detail-frame.orders .detail-box {
  max-width: 270px;
  width: 100%;
}
.detail-frame.orders .detail-box .detail-form {
  width: 100%;
  padding-left: 0;
}
.detail-frame.orders .detail-box .detail-form ul {
  margin: 0;
}
.detail-frame:after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.detail-frame .detail-box {
  max-width: 370px;
  margin-left: auto;
  width: 100%;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.detail-frame .detail-box .freight {
  width: 90px;
  height: 90px;
  background: #0C0C0C;
  float: left;
  border-radius: 50%;
  text-align: center;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.detail-frame .detail-box .detail-form {
  width: calc(100% - 90px);
  float: right;
  padding-left: 10px;
}
.detail-frame .detail-box .detail-form .discount {
  margin-bottom: 35px;
}
@media screen and (max-width: 600px) {
  .detail-frame .detail-box .detail-form .discount.code span {
    display: none;
  }
  .detail-frame .detail-box .detail-form .discount.code input {
    width: calc(100% - 35px);
  }
}
.detail-frame .detail-box .detail-form .discount span {
  text-align: left;
  width: 100px;
  display: inline-block;
}
.detail-frame .detail-box .detail-form .discount input {
  border: 0;
  border-bottom: 1px #0C0C0C solid;
  border-radius: 0;
  display: inline-block;
  width: calc(100% - 135px);
  box-sizing: border-box;
  height: 20px;
  line-height: 20px;
}
.detail-frame .detail-box .detail-form .discount a {
  width: 35px;
  height: 20px;
  background: #0C0C0C;
  color: #fff;
  display: inline-block;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
}
.detail-frame .detail-box .detail-form .discount a:hover {
  text-decoration: none;
}
.detail-frame .detail-box .detail-form select {
  width: 100%;
  background: #fff;
  border-color: #0C0C0C;
  text-align-last: center;
  margin: 0;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.detail-frame .detail-box .detail-form ul {
  margin: 35px 0 0 0;
}
.detail-frame .detail-box .detail-form ul hr {
  border-color: #0C0C0C;
}
.detail-frame .detail-box .detail-form ul li {
  margin-bottom: 10px;
}
.detail-frame .detail-box .detail-form ul li:after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.detail-frame .detail-box .detail-form ul li font {
  text-align: right;
  width: 125px;
  display: inline-block;
  float: left;
}
.detail-frame .detail-box .detail-form ul li span {
  text-align: right;
  width: calc(100% - 125px);
  display: block;
  float: right;
}
.detail-frame .detail-box .detail-form ul li span.total {
  width: calc(100% - 125px);
  text-align: right;
  color: #0C0C0C;
}

.page-frame .title {
  letter-spacing: 0.2em;
  text-align: center;
  color: #0d8480;
  margin-bottom: 20px;
  margin-top: 0;
  font-family: "Montserrat-Medium", "Noto_Bold";
}
.page-frame article {
  max-width: 830px;
  width: 100%;
  margin: auto;
}
.page-frame article img {
  max-width: 100%;
}

.news-frame .title {
  letter-spacing: 0.2em;
  text-align: center;
  color: #0d8480;
  margin-bottom: 20px;
  margin-top: 0;
  font-family: Montserrat-Medium;
}
.news-frame .news-box {
  max-width: 1030px;
  padding: 0 15px;
  width: 100%;
  margin: auto;
}
.news-frame .news-box .news-conetent {
  padding: 0 10px;
  margin-bottom: 30px;
}
.news-frame .news-box .news-conetent .bg {
  overflow: hidden;
  border-radius: 10px;
  display: block;
  width: 100%;
  padding-top: 100%;
  overflow: hidden;
  position: relative;
}
.news-frame .news-box .news-conetent h5 {
  font-size: 16px;
  line-height: 25px;
  height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 0;
  font-family: "Montserrat-Medium", "Noto_Medium";
}
.news-frame .news-box .news-conetent p {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0;
  height: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.news-frame .news-box .news-conetent span {
  font-size: 12px;
  line-height: 20px;
  display: block;
  margin-top: 10px;
}
.news-frame .news-box .news-conetent span:before {
  content: " ";
  display: inline-block;
  margin-right: 10px;
  vertical-align: -6px;
}

.news-content {
  max-width: 780px;
  width: 100%;
  margin: auto;
}
.news-content .title {
  font-size: 25px;
  line-height: 35px;
  font-family: "Montserrat-Medium", "Noto_Medium";
}
.news-content .time {
  font-size: 16px;
  line-height: 35px;
  display: block;
}
.news-content .time:before {
  content: " ";
  display: inline-block;
  margin-right: 10px;
  vertical-align: -4px;
}
.news-content .news-content-box {
  margin: 10px 0;
}

.markdown-box {
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
}
.markdown-box .markdown img {
  display: block;
  width: 100%;
  max-width: 100%;
}