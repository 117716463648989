@charset "UTF-8";

@import 'icon';   //匯入拼接小圖


@include spritesCSS("icon");

#wrapper{
	min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    overflow: hidden;
    background: #FCFBFA;
}

#main{}

#content-frame{
	max-width: 1530px;
	width: 100%;
	margin: 20px auto auto auto;
	padding:30px 10px;
	min-height: 60vh; 
	/*display: flex;
	align-items: center;*/
}

.login-frame{

	.login{
		max-width: 800px;
		width: 100%;
		margin: auto;
		display: flex;
		justify-content:space-between;
		color:  #0c0c0c;

		@media screen and (max-width: 700px){display: block;}

		.box{
			max-width: 295px;
			width: 100%;
			display: block;

			@media screen and (max-width: 700px){
				margin:30px auto;
			}


			.link-style{
				width: 100%;
				background:#0c0c0c;
				color: #fff;
				width: 100%;
				display: block;
				text-align: center;
				text-decoration: none;
				padding: 2px 0;
				border: 0;
			}

			.or{
				font-size: 20px;
				margin: 20px 0;
			}
		}

		a{text-decoration: underline;}

		h3{
			font-size: 30px;
			border-bottom: 1px #0C0C0C solid;
			padding-bottom: 5px;
		
		}

		form{

			margin: 30px 0;

			[type="text"],[type="email"],[type="password"],[type="email"],select{
				margin-bottom: 15px;
			}
		}

	}
}

.modify-member-frame{
	width: 100%;
	form{
		max-width: 520px;
		width: 100%;
		margin: auto;
	}

	a{font-family: 'Montserrat-Medium','Noto_Medium';}
}

.group{
	display: flex;
	margin-bottom:  15px;

	@media screen and (max-width: 600px){
		&.flex{
			display: flex;

			>label{
				width: auto;
			}

			>.input-styel{flex: 1 0;}
		}
	}

	@media screen and (max-width: 600px){display: block;}

	>label{
		width: 140px;
		text-align: right;
		margin-bottom: 0;
		line-height: 25px;

		@media screen and (max-width: 600px){
			width: 100%;
			text-align: left;
		}
	}

	>.input-styel{
		width: calc( 100% - 140px);
		padding-left: 5px;
		line-height: 25px;
		
		@media screen and (max-width: 600px){width: 100%;padding-left: 0;}

		.address{
			display: flex;
			justify-content:space-between;
			margin-bottom: 15px;
			
			*{
				width: 32%;
			}

			[type="text"]{background: #f1f1f1;}
		}
	}
}

[type="text"],[type="email"],[type="password"],select{
	width: 100%;
	border: 1px #0C0C0C solid;
	margin: 10px 0;
	height: 25px;
	line-height: 25px;
	background: transparent;
	padding:0 10px;
	margin: 0;
}

select{
	appearance:none;
	-moz-appearance:none;
	-webkit-appearance:none;
	background-image:url(../img/select-arrow.png);
	background-size: 22px 8px;
	background-repeat: no-repeat;
	background-position: right;
}

[type="submit"]{
	background: #0C0C0C;
	color: #fff;
	width: 50px;
	border: 0;
	padding:2px 0;
	margin: auto;
	display: block;
}

hr{border-color: #0C0C0C;}

.order-frame{
	max-width: 1100px;
	width: 100%;
	margin:auto;

	h2{
		font-size: 16px;
		text-align: center;
		margin: 0 0 20px 0;
		font-family: 'Montserrat-Medium','Noto_Medium';
	}

	.detail-frame{
		.detail-box{
			max-width: 200px;
			width: 100%;

			.detail-form{
				width: 100%;
			}
		}
	}
	

	.order-table{
		&.dividend{
			table tbody tr td{
				@media screen and (max-width: 740px){
					width: 100%;
				}
			}
		}
		table{
			width: 100%;

			@media screen and (max-width: 740px){
				display: block;
				width: 100%;
			}

			thead{
				border-top: 1px #0C0C0C solid;
				border-bottom: 1px #0C0C0C solid;

				@media screen and (max-width: 740px){display: none;}

				tr{
					th{
						padding:5px;
						font-size: 14px;
						font-weight: bold;
						text-align: center;
						white-space:nowrap;

					}
				}
			}

			tbody{
				@media screen and (max-width: 740px){
					display: block;
					width: 100%;

					*{
						display: block;
						width: 100%;
					}
				}

				tr{
					border-bottom: 1px #0C0C0C solid;

					@media screen and (max-width: 740px){
						padding:5px 0;

						&:nth-child(1){
							border-top: 1px #0C0C0C solid;
						}

						&:after {
							content: " ";
							display: block;
							height: 0;
							clear: both;
							visibility: hidden;
						}
					}

					td{
						text-align: center;
						font-size: 12px;
						padding:7px 5px;
						font-family: 'Montserrat-Medium','Noto_Medium';

						font{
							display: none;
						}

						a{
							text-decoration: underline;
						}

						button{
							background: #ddd;
							border: 0;
							border-radius: 5px;
							font-family: Noto_Regular;
						}

						@media screen and (max-width: 740px){
							width: 50%;
							float: left;
							display: flex;
							text-align: left;
							font-size: 14px;
							font-family:'Montserrat-Regular','Noto_Regular';

							font{
								display: block;
								width: 70px;
							}

							span{
								width: calc( 100% - 70px);
							}

							a{
								text-align: center;
								text-decoration: none;
								border: 1px #0C0C0C solid;
								padding:5px;
							}

							button{
								width: 90px;
								margin-left: auto;
								padding:5px;
							}

							&.block{
								width: 100%;
								display: block;
								font{
									width: auto;
								}

								span{
									width: auto;
									flex: 1 0;
								}
								
							}
						}
					}
				}
			}
		}
	}

	.details-order{

		.details-table1{

			@media screen and (max-width: 740px){
				*{
					display: block;
					width: 100%;
				}
			}

			thead{
				border-bottom: 1px #0C0C0C solid;
				border-top: 1px #0C0C0C solid;

				tr th{
					text-align: center;
					padding:5px;
				}
			}

			>tbody{
				>tr >td{
					padding:20px 10px;

					table tbody tr {
						
						@media screen and (max-width: 740px){
							&:after {
								content: " ";
								display: block;
								height: 0;
								clear: both;
								visibility: hidden;
							}
						}

						td{
							padding:5px;

							@media screen and (max-width: 740px){
								width: 50%;
								float: left;
								text-align: left;
								padding:10px 5px;
							
								&.block{
									width: 100%;
									display: block;
								}
							}
						}
					}
				}
			}
		}
	}
}

#prod-list {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  font-size: 14px;

  @media screen and (max-width: 740px) {
  	*{

  	}
  }

  thead{
  	tr th{
  		border-bottom:  1px solid #0C0C0C;
	    padding: 5px;
	    vertical-align: top;
	    border-top:  1px solid #0C0C0C;

  	}

  	@media screen and (max-width: 740px) {display: none;}
  }

  tbody{

	tr{
		border-bottom:  1px solid #0C0C0C;

	  	@media screen and (max-width: 740px) {
	  		&:nth-child(1){border-top: 1px solid #0C0C0C;}
			margin-bottom: 20px;
			padding-bottom: 20px;
			border-bottom: 1px solid #0C0C0C;
			&:after{display:block;content:'';clear:both;}
			display:flex;
			flex-wrap:wrap;
		}

		td{
		    padding:10px 5px;
		    vertical-align: top;

		    select{height: 20px;line-height: 20px;}

		    .p-img {
			    display: block;
			    width: 80px;
			    margin: 0 auto;

			    img{
			    	width: 100%;
			    }

			    .cart-payment &  {
					width: 40px;
					height: 65px;
			    }

			     @media screen and (max-width: 740px) {
			     	width: 140px;
			     }
		  	}

		  	span.offer{
			    display: block;
			    color: #C1272D;
			}

			.info{
			  	background: #C1272D;
			  	font-size: 12px;
			  	display: inline-block;
			  	color: #fff;
			  	padding:3px 5px;
			  	margin-top: 5px;

			  	&.mob{display: none;}

			  	@media screen and (max-width: 740px){
			  		&.pc{display: none;}

			  		&.mob{display: inline-block;}
			  	}
			}

			&.gift{
		    	text-align: center;
		    	@media screen and (max-width: 740px){width: 100%;}
		    }

		    del {
			    display: block;
			    text-indent: -999px;
			    overflow: hidden;
			    margin: 0 auto;
			    cursor: pointer;
			    @extend .icon;
			    @extend %icon-menu-delete;
			    transform: scale(0.7);
		  	}

		  	 &.mob-show{
		  	 	display: none;

		  	 	@media screen and (max-width: 740px) {display: block;}
		  	 }

		    @media screen and (max-width: 740px) {
		    	width: 50%;
		        float: left;
		        text-align: left;

		        &.total{text-align: right;}

		        &.block{width: 100%;}

			    &.pc-show{display: none;}

			    font{
			    	display: inline-block;
			    	width: auto;
			    }

			    &:before {
			        content: attr(data-th); 
			        display: inline-block;
			        color: #0C0C0C;
			    }
		  	}
		}
  	}
  }  
}

.detail-frame{
  margin-left: auto;
  &.orders{
    margin: auto;

    .detail-box{
      max-width: 270px;
      width: 100%;
      
      .detail-form{
        width: 100%;
        padding-left: 0;

        ul{
          margin: 0;
        }
      }
    }
  }

  &:after {
    content: " ";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }

  .detail-box{
    max-width: 370px;
    margin-left: auto;
    width: 100%;
    font-size: 16px;
    display:flex;
    align-items:center;
    justify-content:center;

    .freight{
      width: 90px;
      height: 90px;
      background: #0C0C0C;
      float: left;
      border-radius: 50%;
      text-align: center;
      font-size: 20px;
      display:flex;
      align-items:center;
      justify-content:center;
      color: #fff;
    }
    
    .detail-form{
      width: calc( 100% - 90px);
      float: right;
      padding-left: 10px;

      .discount{
        margin-bottom: 35px;

        &.code{
          @media screen and (max-width: 600px){
            span{display: none;}
          
            input{width: calc( 100% - 35px);}  
          }
        }

        span{
          text-align:left;
          width: 100px;
          display: inline-block;
        }

        input{
          border: 0;
          border-bottom: 1px #0C0C0C solid;
          border-radius: 0;
          display: inline-block;
          width: calc( 100% - 135px);
          box-sizing: border-box;
          height: 20px;
          line-height: 20px;
        }


        a{
          width: 35px;
          height: 20px;
          background: #0C0C0C;
          color: #fff;
          display: inline-block;
          text-align: center;
          line-height: 20px;
          font-size: 12px;

          &:hover{
            text-decoration: none;
          }
        }
      }

      select{
        width: 100%;
        background: #fff;
        border-color: #0C0C0C;
        text-align-last: center;
        margin: 0;
        box-sizing: border-box;
        margin-bottom: 20px;
      }

      ul{
        margin: 35px 0 0 0;

        hr{
          border-color: #0C0C0C;
        }

        li{
          margin-bottom: 10px;

          &:after {
            content: " ";
            display: block;
            height: 0;
            clear: both;
            visibility: hidden;
          }

          font{
            text-align: right;
            width: 125px;
            display: inline-block;
            float: left;
          }

          span{
            text-align: right;
            width: calc( 100% - 125px);
            display: block;
            float: right;

            &.total{
              width:  calc( 100% - 125px);
              text-align: right;
              color: #0C0C0C;
            }
          }
        }
      }

    }
  }
}

.page-frame{
	.title{
		letter-spacing: 0.2em;
		text-align: center;
		color: #0d8480;
		margin-bottom: 20px;
		margin-top: 0;
		font-family: 'Montserrat-Medium','Noto_Bold';
	}

	article{
		max-width: 830px;
		width: 100%;
		margin: auto;

		img{max-width: 100%;}
	}
}

.news-frame{
	.title{
		letter-spacing: 0.2em;
		text-align: center;
		color: #0d8480;
		margin-bottom: 20px;
		margin-top: 0;
		font-family: Montserrat-Medium;
	}

	.news-box{
		max-width: 1030px;
		padding:0 15px;
		width: 100%;
		margin:auto;
	
		.news-conetent{
			padding:0 10px;
			margin-bottom: 30px;

			.bg{
				overflow: hidden;
				border-radius: 10px;
				display: block;	
				width: 100%;
			    padding-top: 100%;
			    overflow: hidden;
			    position: relative;
			}

			h5{
				font-size: 16px;
				line-height: 25px;
				height: 50px;
				overflow: hidden;
			    text-overflow: ellipsis;
			    display: -webkit-box;
			    -webkit-line-clamp: 2;
			    -webkit-box-orient: vertical;
			    margin-bottom: 0;
			    font-family: 'Montserrat-Medium','Noto_Medium';
			}

			p{
				font-size: 14px;
				line-height: 20px;
				margin-bottom: 0;
				height: 60px;
				overflow: hidden;
			    text-overflow: ellipsis;
			    display: -webkit-box;
			    -webkit-line-clamp: 3;
			    -webkit-box-orient: vertical;
			}

			span {
				font-size: 12px;
				line-height: 20px;
				display: block;
				margin-top: 10px;

				&:before{
					content:" ";
					display: inline-block;
					margin-right: 10px;
					@extend .icon;
					@extend %icon-clock;
					vertical-align: -6px;
				}
			}
		}
	}
}

.news-content{
	max-width: 780px;
	width: 100%;
	margin:auto;

	.title{
		font-size: 25px;
		line-height: 35px;
		font-family: 'Montserrat-Medium','Noto_Medium';
	}

	.time{
		font-size: 16px;
	    line-height: 35px;
	    display: block;

	    &:before{
	    	content:" ";
	    	@extend .icon;
			@extend %icon-clock;
			display: inline-block;
			margin-right: 10px;
			vertical-align: -4px;
	    }
	}

	.news-content-box{
		margin: 10px 0;
	}
}

.markdown-box{
	max-width: 900px;
	width: 100%;
	margin:0 auto;

	.markdown{

		img{
			display: block;
			width: 100%;
			max-width: 100%;
		}
	}
}
